import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { documentActions } from '../../store/actions'
import { documentRequests } from "../../services";

import {
    Typography,
    Card,
    Dialog,
    DialogContent,
    CircularProgress, useTheme
} from '@mui/material';

import { useStyles } from './ModalStyles'
// import { mainStyles } from '../../styles';
import { UseImage, CustomLoadingOverlay } from "../index";

import CloseIcon from '@mui/icons-material/Close';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HeadsetIcon from '@mui/icons-material/Headset';
// import FaceIcon from '@mui/icons-material/Face';
// import ine from '../../assets/id.svg'
import evAudio from '../../assets/evAudio.svg'
import evVideo from '../../assets/evVideo.svg'
import procesandoVideo from '../../assets/procesando_video.gif'
import { mainStyles } from '../../theme/mainStyles'

export function BioVisual({ docId, docVersionId, cardName, cardDate, cardPercentage, docText, readText, statusVideo }) {
    const theme = useTheme()
    const localStyles = useStyles(theme)
    const cardStyle = mainStyles.cards()
    const dispatch = useDispatch()
    const biometriDocFirst = useSelector(state => state.BioDocument)
    const biometriDocVersion = useSelector(state => state.BioDocumentVersion)
    const [open, setOpen] = useState(false)
    const [biometriDoc, setBiometriDoc] = useState(null)

    const handleOpen = () => {
        setOpen(true);
        getDocumentInfo()
    };

    const getDocumentInfo = async () => {
        /* console.log('docId:: ', docId)
        const response = await documentRequests.getDocumentInfo(docId);
        console.log('RESPONSE DOC:: ', response) */
        if(docVersionId){
            console.log('docVersionId::',docVersionId)
            dispatch(documentActions.GetBioDocumentVersion(docVersionId, /* response?.Body?.Extension */))
        }else{
            dispatch(documentActions.GetBioDocument(docId, /* response?.Body?.Extension */))
        }
    }

    useEffect(() => {
        if(docVersionId){
            setBiometriDoc(biometriDocVersion)
        }else{
            setBiometriDoc(biometriDocFirst)
        }
    }, [biometriDocVersion, biometriDocFirst])
    

    const handleClose = () => {
        setOpen(false);
    };
console.log('biometriDoc::',biometriDoc)
    return (
        <div className={cardStyle.modalCard}>
            <Card className={cardStyle.bioModalCard} onClick={handleOpen}>
                <Typography variant="p" className={cardStyle.titleCard}>{cardName}</Typography>
                {
                    cardName.includes('Audio')
                        ? <UseImage src={evAudio} className={localStyles.icon} type='img' />
                        : cardName.includes('Videograbación')
                            ? <UseImage src={evVideo} className={localStyles.icon} type='img' />
                            : ''
                }
                <Typography variant="p" className={cardStyle.cardMatch}>{`Similitud en leyenda de consentimiento: ${cardPercentage}%`}</Typography>
            </Card>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg">
                <CloseIcon style={{ color: "#898B8D" }} className={localStyles.closeIcon} onClick={handleClose} />
                <div className={localStyles.lineBio}></div>
                <DialogContent className={localStyles.bioModal}>
                    <Typography variant="p" className={localStyles.titleModal}>{cardName}</Typography>
                    {statusVideo !== "VideoCompleto" && statusVideo !== "Rechazado" && biometriDoc?.items === undefined ?
                        <UseImage className={localStyles.imgProcesandoVideo} src={procesandoVideo} type='img' />
                        : biometriDoc?.loading
                            ? <CustomLoadingOverlay
                                active={biometriDoc?.loading} text="Espera un momento por favor..."
                            /> :
                            biometriDoc?.extension === '.mp4' ?
                                <video className="videoClass" width="80%" height="70%" controls autoPlay loop poster="fotograma.png">
                                    <source src={biometriDoc?.items} type={biometriDoc?.type} />
                                    <p>Tu navegador no soporta HTML5</p>
                                </video>
                                : biometriDoc?.extension === '.pdf'
                                    ? <object width="80%" height="80%" data={biometriDoc?.items} type="application/pdf"> </object>
                                    : biometriDoc?.extension === '.jpg'
                                        ? <UseImage src={biometriDoc?.items} type='img' className={localStyles.fileModal} />
                                        : biometriDoc?.extension === '.mp3'
                                            ? <audio controls>
                                                <source src={biometriDoc?.items} type={biometriDoc?.type} />
                                                <p>Tu navegador no soporta HTML5</p>
                                            </audio>
                                            : ''}
                    <div className={localStyles.resultInfoSection}>
                        {statusVideo !== "VideoCompleto" && statusVideo !== "Rechazado" && biometriDoc?.items === undefined ?
                            <Typography variant="p" className={localStyles.modalEstatusProcesando}><b>Procesando video</b></Typography>
                            : <Typography variant="p" className={statusVideo === "Aprobado" ? localStyles.modalEstatusProcesandoSucces : localStyles.modalEstatusProcesando}>{statusVideo}</Typography>}

                        <Typography variant="p" className={localStyles.resultTitle}>Verificación de resultados</Typography>
                        <div className={localStyles.metadaSection}>
                            <div className={localStyles.eachData}>
                                <Typography variant="p" className={localStyles.modalBioInfo}><b>Similitud en leyenda de consentimiento:</b></Typography>
                                <Typography variant="p" className={localStyles.modalBioInfo}>{`${cardPercentage}%`}</Typography>
                            </div>
                            <div className={localStyles.eachData}>
                                <Typography variant="p" className={localStyles.modalBioInfo}><b>Fecha de Captura:</b></Typography>
                                <Typography variant="p" className={localStyles.modalBioInfo}>{cardDate}</Typography>
                            </div>

                            <div className={localStyles.eachData}>
                                <Typography variant="p" className={localStyles.modalBioInfo}><b>Texto a Leer:</b></Typography>
                                <Typography variant="p" className={localStyles.txtToRead}>{docText}</Typography>
                            </div>
                            <div className={localStyles.eachData}>
                                <Typography variant="p" className={localStyles.modalBioInfo}><b>Texto Leído:</b></Typography>
                                <Typography variant="p" className={localStyles.txtToRead}>{readText}</Typography>
                            </div>
                        </div>
                    </div>
                </DialogContent>

            </Dialog>
        </div>
    )
}