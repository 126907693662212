export const documentConstants = {
    DOCUMENT_FILE_REQUEST: 'DOCUMENT_FILE_REQUEST',
    DOCUMENT_FILE_SUCCESS: 'DOCUMENT_FILE_SUCCESS',
    DOCUMENT_FILE_FAILURE: 'DOCUMENT_FILE_FAILURE',

    DOCUMENT_BIO_REQUEST: 'DOCUMENT_BIO_REQUEST',
    DOCUMENT_BIO_SUCCESS: 'DOCUMENT_BIO_SUCCESS',
    DOCUMENT_BIO_FAILURE: 'DOCUMENT_BIO_FAILURE',

    DOCUMENT_BIO_VERSION_REQUEST: 'DOCUMENT_BIO_VERSION_REQUEST',
    DOCUMENT_BIO_VERSION_SUCCESS: 'DOCUMENT_BIO_VERSION_SUCCESS',
    DOCUMENT_BIO_VERSION_FAILURE: 'DOCUMENT_BIO_VERSION_FAILURE',

    DOCUMENT_UPDATEMETADATA_REQUEST: 'DOCUMENT_UPDATEMETADATA_REQUEST',
    DOCUMENT_UPDATEMETADATA_SUCCESS: 'DOCUMENT_UPDATEMETADATA_SUCCESS',
    DOCUMENT_UPDATEMETADATA_FAILURE: 'DOCUMENT_UPDATEMETADATA_FAILURE',

    CARGADOC_WITH_SECTION_REQUEST: 'CARGADOC_WITH_SECTION_REQUEST',
    CARGADOC_WITH_SECTION_SUCCESS: 'CARGADOC_WITH_SECTION_SUCCESS',
    CARGADOC_WITH_SECTION_FAILURE: 'CARGADOC_WITH_SECTION_FAILURE',

    UPLOAD_SECTION_REQUEST: 'UPLOAD_SECTION_REQUEST',
    UPLOAD_SECTION_SUCCESS: 'UPLOAD_SECTION_SUCCESS',
    UPLOAD_SECTION_FAILURE: 'UPLOAD_SECTION_FAILURE',

    REGISTER_DATA_REQUEST: 'REGISTER_DATA_REQUEST',
    REGISTER_DATA_SUCCESS: 'REGISTER_DATA_SUCCESS',
    REGISTER_DATA_FAILURE: 'REGISTER_DATA_FAILURE',

    GET_OCRDATA_REQUEST: 'GET_OCRDATA_REQUEST',
    GET_OCRDATA_SUCCESS: 'GET_OCRDATA_SUCCESS',
    GET_OCRDATA_FAILURE: 'GET_OCRDATA_FAILURE',

    GET_DOCUMENTS_BY_ROL_REQUEST: 'GET_DOCUMENTS_BY_ROL_REQUEST',
    GET_DOCUMENTS_BY_ROL_SUCCESS: 'GET_DOCUMENTS_BY_ROL_SUCCESS',
    GET_DOCUMENTS_BY_ROL_FAILURE: 'GET_DOCUMENTS_BY_ROL_FAILURE',

    DOWNLOAD_DOC_REQUEST: 'DOWNLOAD_DOC_REQUEST',
    DOWNLOAD_DOC_SUCCESS: 'DOWNLOAD_DOC_SUCCESS',
    DOWNLOAD_DOC_FAILURE: 'DOWNLOAD_DOC_FAILURE',

}
