import { Outlet, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Footer } from "../../components/Footer";
import { Box, styled } from "@mui/material";
import { HeaderAppBar } from "../../components/HeaderAppBar";
import { Alert, Header } from "../../components";
import { Drawer } from "../../components/Drawer";
import { useEffect } from "react";
import { clearCacheData, Logout } from "../../utils";

const OutletWrapper = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  boxShadow: "0px 18px 53px rgba(0, 0, 0, 0.14)",
  borderRadius: "10px",
  margin: "15px",
  // border: '1px solid blue',
  padding: "0.5em", //"1.5em",
  // backgroundColor: "#CACCCE",
  [theme.breakpoints.up("lg")]: {
    margin: "10px 15px 5px 15px",
    height: "100vh",
  },
  [theme.breakpoints.down("md")]: {
    margin: "10px 10px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "10px 0px",
    // margin: "0px",
    padding: "0.6em",
    borderRadius: "10px 10px 0px 0px",
    boxShadow: "none",
    marginBottom: 0,
  },
  overflowY: "auto !important",
}));

const MarginPrincipal = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    margin: "0px 45px 0px 0px",
    display: "grid !important",
    gridTemplateColumns: "17% 83%",
    gridAutoRows: "100vh !important",
    overflowY: "hidden !important",
    "&::-webkit-scrollbar": {
      width: "0",
      background: "transparent",
    },
    overflowX: "hidden",
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
    },
  },
}));

const RootStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "#F7F8FA",
  width: "100%",
  minHeight: "calc(100vh - 120px)",
  margin: "0px auto",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

export default function Home() {
  const [drawer, setdrawer] = useState(false);
  const openDrawer = () => setdrawer(true);
  const closeDrawer = () => setdrawer(false);
  const [isActive, setIsActive] = useState(true);

  const navigation = useNavigate();

  useEffect(() => {
    const inactivityTime = process.env.REACT_APP_INACTIVITY_TIME ?? 900000

    console.log('inactivityTime::', process.env.REACT_APP_INACTIVITY_TIME,inactivityTime)
    //Logout auto
    let timeout = setTimeout(() => {
      if (isActive) {
        closeSesion();
      }
    }, Number(inactivityTime)/* 900000 */); // 15 minutes -> 1 hora

    const handleActivity = () => {
      //Reset timer
      clearTimeout(timeout);
      setIsActive(true);
      timeout = setTimeout(() => {
        if (isActive) {
          closeSesion();
        }
      }, Number(inactivityTime)/* 900000 */);
    };

    const closeSesion = () => {
      Alert(
        {
          icon: "warning",
          title: "Sesión expirada. Inicia sesión nuevamente",
          allowOutsideClick: false,
          allowEscapeKey: false,
          onlyOnSuccess: true,
        },
        () => {
          Logout();
          clearCacheData().catch((error) => {
            console.error("Caught an error:", error);
          });
          window.location.reload(true);

          navigation("/auth/login");
        }
      );
    };

    window.addEventListener("click", handleActivity);
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, []);

  return (
    <MarginPrincipal>
      <HeaderAppBar open={drawer} openDrawer={openDrawer} />
      <RootStyle>
        <Box>
          <Header />
        </Box>
        <OutletWrapper>
          <Outlet />
        </OutletWrapper>
        <Footer />
      </RootStyle>
      <Drawer open={drawer} onClose={closeDrawer} />
    </MarginPrincipal>
  );
}
