import React, { useState, useEffect } from 'react'
import {
    Typography,
    Card,
    Dialog,
    DialogContent,
    CircularProgress, useTheme
} from '@mui/material';

import { useStyles } from './ModalStyles'
import { mainStyles } from '../../theme/mainStyles'
import { UseImage } from '../index';

import CloseIcon from '@mui/icons-material/Close';


export function MiniBioVisual({ showDoc, cardName, cardMatch, cardDate, cardPercentage, snapShot, metAdic }) {
    const theme = useTheme()
    const localStyles = useStyles(theme)
    const cardStyle = mainStyles.cards()
    const [open, setOpen] = useState(false)
    const [listMetadata, setListMetadata] = useState([])

    useEffect(() => {
        let metadata = metAdic?.find(item => item.grupo === "Validación de evidencias")
            ?.documentos?.find(item => item.nombre === "Video frame 1")
            ?.metaData?.filter(item => item.GroupName === "Reconocimiento facial")
        if (metadata !== null && metadata !== undefined) {
            console.log("metadata entro", metadata)
            setListMetadata(metadata)
        }
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <div className={cardStyle.modalCard}>
            <Card className={cardStyle.bioModalCard} onClick={handleOpen}>
                <Typography variant="p" className={cardStyle.titleCard}>{cardName}</Typography>

                <img src={showDoc} className={cardName === 'Selfie' ? localStyles.miniSelfie : localStyles.miniImg} />

                {
                    cardName === 'Selfie'
                        ? <Typography variant="p" className={cardStyle.cardMatch}>&nbsp;</Typography>
                        : <Typography variant="p" className={cardStyle.cardMatch}>{cardPercentage ? `Similitud facial: ${cardPercentage}%` : "Similitud facial: NA"}</Typography>
                }
            </Card>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
                <CloseIcon style={{ color: "#898B8D" }} className={localStyles.closeIcon} onClick={handleClose} />
                <div className={localStyles.lineBio}></div>
                <DialogContent className={cardName === 'Selfie' ? localStyles.miniBioModal : localStyles.bioModal}>
                    <Typography variant="p" className={localStyles.titleModal}>{cardName}</Typography>
                    <img src={showDoc} className={localStyles.fileModal} />
                    {
                        cardName === 'Selfie'
                            ? <Typography variant="p" className={localStyles.modalBioInfo}></Typography>
                            :
                            <div className={localStyles.resultInfoSection}>
                                <Typography variant="p" className={localStyles.resultTitle}>Verificación de resultados</Typography>
                                <div className={localStyles.metadaSection}>
                                    <div className={localStyles.eachData}>
                                        <Typography variant="p" className={localStyles.modalBioInfo}><b>Similitud facial:</b></Typography>
                                        <Typography variant="p" className={localStyles.modalBioInfo}>{cardPercentage ? `${cardPercentage}%` : "NA"}</Typography>
                                    </div>
                                    <div className={localStyles.eachData}>
                                        <Typography variant="p" className={localStyles.modalBioInfo}><b>Fecha de captura:</b></Typography>
                                        <Typography variant="p" className={localStyles.modalBioInfo}>{cardDate ?? 'NA'}</Typography>
                                    </div>
                                    <div className={localStyles.eachData}>
                                        <Typography variant="p" className={localStyles.modalBioInfo}><b>Resultado de similitud:</b></Typography>
                                        <Typography variant="p" className={localStyles.modalBioInfo}>{cardMatch ?? 'NA'}</Typography>
                                    </div>
                                    {cardName === 'Video frame' &&
                                        <div className={localStyles.eachData}>
                                            <Typography variant="p" className={localStyles.modalBioInfo}><b>Captura en video:</b></Typography>
                                            <Typography variant="p" className={localStyles.modalBioInfo}>{snapShot}</Typography>
                                        </div>}

                                    {listMetadata.length > 0 ? listMetadata.map((item) => {
                                        console.log("Validación de evidencias item: ", item)
                                        return <div className={localStyles.eachData}>
                                            <Typography variant="p" className={localStyles.modalBioInfo}><b>{item.Name}:</b></Typography>
                                            <Typography variant="p" className={localStyles.modalBioInfo}>{item.Value}</Typography>
                                        </div>
                                    }) : null
                                    }
                                </div>
                                {/* <Typography variant="p" className={localStyles.modalBioInfo}><b> Porcentaje de similitud: </b>{+" ."}
                               <b>| Fecha de Captura: </b>{cardDate +" ."}
                               <b>| Resultado de similitud: </b>{cardMatch +" ."}
                               </Typography> */}
                            </div>
                    }
                </DialogContent>

            </Dialog>
        </div >
    )
}