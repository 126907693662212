import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useTheme,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField as InputText,
  styled,
  Stack,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { FormProvider, Controller, useForm } from "react-hook-form";

import { InputField, Alert, CustomLoadingOverlay } from "../index";
import {
  B64toBlob,
  GetMimeType,
  constPathRoot,
  getSizeBlob,
} from "../../utils";
import { executeSearchRequests } from "../../services";
import { documentActions, executeSearchActions } from "../../store/actions";
import { Logout } from "../../utils";
import VisorPDF from "../Visor/VisorPDF";

const TextField = styled(InputText)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    fontSize: 14,
    width: "100%",
    "& fieldset legend span": {
      display: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 0,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    fontFamily: '"UniversNextforHSBC-Regular", sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },
  },
  "& label.Mui-error": {
    color: "#000 !important",
  },
  "& .MuiSelect-select": {
    paddingTop: "13px",
    paddingBottom: "0px",
    height: 35,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B !important;",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#000 !important",
  },
  "& form .MuiInputLabel-root.Mui-error": {
    color: "#000 !important",
  },
}));

export function FormDynamic(props) {
  const theme = useTheme();
  const formMethods = useForm();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState,
    register,
    getValues,
    setError,
    clearErrors,
    unregister,
  } = useForm({ mode: "onBlur" });
  const [loadView, setLoadView] = useState(false);
  const [properties, setProperties] = useState("");
  const [loadPostalCode, setLoadPostalCode] = useState(false);
  const [postalCodeTemp, setPostalCodeTemp] = useState(false);
  const [tryCP, setTryCP] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [dataControls, setDataControls] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const [docToShow, setDocToShow] = useState(null);
  const [base64SizePDF, setBase64SizePDF] = useState(null);

  const [disabledContinueButton, setDisabledContinueButton] = useState(false);

  const [formularioTerminado, setFormularioTerminado] = useState(false);

  const dataSearchCP = useSelector((state) => state.SearchData);
  const file = useSelector((state) => state.FileInfo);
  const downloadDoc = useSelector((state) => state.DownloadDocument);
  let tempQuest = props.EditMetadataCustom;

  let questions2 = questions;

  const dispatch = useDispatch();
  const watchAllFields = watch();

  useEffect(() => {
    //unmount
    return () => {
      if (!formularioTerminado && !loadView /* && !props?.loadViewInfo */) {
        !!props.handleUnmountForm && props.handleUnmountForm(getValues());
      }
    };
  }, []);
  const checkRequiredFields = (data) => {
    const hasMissingRequiredFields = questions?.some((itemGroup) => {
      return itemGroup.questions?.some((question) => {
        const value = data[question.Id];
        return (
          question.Required &&
          (value === undefined || value === null || value === "")
        );
      });
    });
    setDisabledContinueButton(hasMissingRequiredFields);
  };
  /* 
  const checkRequiredFields = () => {
    const disabled = questions.some((itemGroup) => {
      return itemGroup.questions.some((question) => {
        const valueQuestion = getValues(`${question.Id}`);
        if (
          question.Required &&
          (valueQuestion === undefined ||
            valueQuestion === null ||
            valueQuestion === "")
        ) {
          return true;
        } else {
          return false;
        }
      });
    });
    setDisabledContinueButton(disabled);
  };
*/
  useEffect(() => {
    checkRequiredFields(getValues());
  }, [watchAllFields]);

  useEffect(() => {
    setTryCP(3);
  }, []);

  useEffect(() => {
    if (
      downloadDoc.items != null &&
      downloadDoc.items != undefined &&
      file?.items?.Stage?.Name == "Cuestionario dinamico Documento"
    ) {
      handleSetDocPreview(downloadDoc.items?.B64Content);
      setLoadView(false);
    } else if (downloadDoc.error != undefined) {
      setLoadView(false);
    }
  }, [downloadDoc]);

  const handleSetDocPreview = (b64) => {
    if (!!b64) {
      const base64 = b64;
      const splitted = "doc.pdf".split(".");
      const extention = splitted[splitted.length - 1];
      const blob = B64toBlob(base64, GetMimeType(extention));
      const sizeBase = getSizeBlob(base64);
      setBase64SizePDF(sizeBase);
      const blobUrl = URL.createObjectURL(blob);
      setDocToShow(blobUrl);
    }
  };

  useEffect(() => {
    if (
      props.StageCurrent?.Stage !== undefined &&
      props.StageCurrent?.Stage !== null
    ) {
      setProperties(props.StageCurrent.Stage);
    }
  }, [props.StageCurrent]);

  useEffect(() => {
    if (props.DataControls !== undefined && props.DataControls !== null) {
      let newJson = [
        {
          llave: props.DataControls?.Id,
          groupTitle: props.DataControls?.Name,
          questions: props.DataControls?.QuestionDefinitions,
        },
      ];
      setQuestions(newJson);
    }
  }, [props.DataControls]);

  useEffect(() => {
    if (properties !== "") {
      if (properties.Description !== undefined) {
        const questionsToSet =
          properties?.Name === "Cuestionario dinamico Documento"
            ? properties?.DocumentalCategory?.DocumentalGroups[0]
                ?.DocumentTypes[0]?.QuestionGroups
            : properties.QuestionGroups;

        let newArray = setGroupNames(questionsToSet);
        setQuestions(newArray);
        setDataControls(newArray);
        if (properties?.Name === "Cuestionario dinamico Documento") {
          setLoadView(true);
          dispatch(
            documentActions.DownloadDocument(
              file.items?.Stage?.DocumentalCategory?.DocumentalGroups[0]
                ?.DocumentTypes[0]?.Document?.Id
            )
          );
        }
      }
    }
  }, [properties]);

  useEffect(() => {
    if (
      props.EditMetadataCustom !== undefined &&
      props.EditMetadataCustom !== null
    ) {
      console.log("props.EditMetadataCustom::", props.EditMetadataCustom);
      let newJson = [tempQuest];
      setQuestions(newJson);
      tempQuest = null;
      setDataControls(newJson);
    }
  }, [props.EditMetadataCustom]);

  useEffect(() => {
    if (questions?.length > 0 && firstLoad) {
      let listDocumentFilter = questions?.map((item) =>
        item.questions?.filter(
          (itemDoc) =>
            itemDoc.Value != undefined &&
            itemDoc.Value != null &&
            itemDoc.Value != ""
        )
      );
      listDocumentFilter.forEach((itemGroup) => {
        itemGroup?.forEach((itemQuestion) => {
          if (itemQuestion.QuestionType == "Fecha") {
            if (
              itemQuestion.Metadata != null &&
              itemQuestion.Metadata?.Value != null
            ) {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion.Metadata?.Value != null
                  ? new Date(itemQuestion.Metadata?.Value)
                  : null
              );
            }
          } else if (itemQuestion.QuestionType === "FechaPrevia") {
            if (
              itemQuestion.Metadata != null &&
              itemQuestion.Metadata?.Value != null
            ) {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion.Metadata?.Value != null
                  ? new Date(itemQuestion.Metadata?.Value)
                  : null
              );
            }
          } else if (itemQuestion.QuestionType === "Hour") {
            if (
              itemQuestion.Metadata != null &&
              itemQuestion.Metadata?.Value != null
            ) {
              const specificTime = new Date();
              const splitHour = itemQuestion.Metadata?.Value?.split(":");
              specificTime.setHours(parseInt(splitHour[0]));
              specificTime.setMinutes(parseInt(splitHour[1]));
              console.log("specificTime 1", specificTime);
              setValue(`${itemQuestion.Id}`, specificTime);
            }
          } else if (itemQuestion.QuestionType === "Radio") {
            if (
              itemQuestion.Metadata != null &&
              itemQuestion.Metadata?.Value != null
            ) {
              setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value);
            }
          } else if (itemQuestion.QuestionType === "Código Postal") {
            if (
              itemQuestion.Metadata != null &&
              itemQuestion.Metadata?.Value != null
            ) {
              setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value);
            }
          } else {
            if (
              itemQuestion.Metadata != null &&
              itemQuestion.Metadata?.Value != null
            ) {
              setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value);
            } else {
              setValue(`${itemQuestion.Id}`, itemQuestion?.Value);
            }
          }
        });
      });
      setFirstLoad(false);
    }
  }, [questions]);

  const setGroupNames = (array) => {
    const newGroup = array.map((group) => {
      return {
        groupTitle: group.Name,
        llave: group.Id,
        questions: group.Questions.map((qn) => {
          const limitDateFunc = (limit, qName) => {
            const maxDate =
              qName?.toUpperCase().includes("NACIMIENTO") &&
              file?.items?.Product == "Siniestros"
                ? 18
                : 0;
            const date = new Date();
            const limitYears =
              qName?.toUpperCase().includes("NACIMIENTO") &&
              file?.items?.Product == "Siniestros"
                ? 110
                : 99;
            const limitDate =
              date.getFullYear() - (limit == "maximum" ? maxDate : limitYears);
            const newDate = new Date().setFullYear(limitDate);
            return newDate;
          };
          let val = qn?.Metadata;
          if (!!qn?.Metadata && qn?.Metadata?.Value === null) {
            val = { ...val, Value: undefined };
          }
          let visible =
            !!qn?.Metadata && qn?.Metadata?.Value !== null ? true : false;
          const disabled =
            file.items.Stage.Id == "952" &&
            (qn.Name === "VIGENCIA POLIZA" || qn.Name === "IMPORTE RECLAMADO")
              ? true
              : false;

          return {
            ...qn,
            ...(!!qn.Metadata && { Metadata: val }),
            GroupName: group.Name,
            Name: qn.Name,
            Value: qn.Metadata !== null ? qn.Metadata?.Value : "",
            QuestionType: qn.QuestionType,
            Options: qn.Options,
            Required:
              file?.items?.Stage?.Name == "Cuestionario dinamico Documento"
                ? false
                : qn.Required,
            Id: qn.Id,
            DefaultValue: null,
            Visible:
              qn.Visible === undefined || qn.Visible === null
                ? qn.Name.includes("Beneficiario")
                  ? false
                  : qn.Name.toUpperCase() !== "OTRO" &&
                    qn.Name != "Causa de rechazo de asegurabilidad" &&
                    qn.Name !=
                      "Especifique otra causa de rechazo de asegurabilidad" &&
                    qn.Name != "Seguro adicional" &&
                    qn.Name != "¿La información registrada es Correcta?" &&
                    !qn.Name.toUpperCase().includes("OTRO PARENTESCO")
                  ? true
                  : (qn.Name.toUpperCase() == "OTRO" ||
                      !qn.Name.toUpperCase().includes("OTRO PARENTESCO")) &&
                    visible
                  ? true
                  : false
                : qn.Visible,
            Captured: null,
            Disabled: disabled,
            ...((qn.Name.toUpperCase().includes("NACIMIENTO") ||
              qn.Name.toUpperCase().includes("INICIO DE SINTOMAS") ||
              qn.Name.toUpperCase().includes("INICIO DE SINTOMAS")) &&
              qn.QuestionType == "FechaPrevia" && {
                minDate: limitDateFunc("minimum", qn.Name),
                maxDate: limitDateFunc("maximum", qn.Name),
              }),
          };
        }),
      };
    });
    return newGroup;
  };

  let path = window.location.pathname;
  let UpdateCtrlQuestionsAsync = (itemCurrent, valueParam, questionsxd) => {
    try {
      if (itemCurrent.ExecuteSearchFunction === "GetBySelectedOption") {
        setLoadView(true);
        let value =
          valueParam === undefined || valueParam === null || valueParam === ""
            ? watch(`${itemCurrent.Id}`)
            : valueParam;
        let request = {
          File_Id: props.fileId,
          Document_Id: null,
          DocumentType_Id: null,
          QuestionGroup: itemCurrent.QuestionGroup,
          Question: itemCurrent.Name,
          Value: value,
        };
        executeSearchRequests
          .GetBySelectedOption(request)
          .then((response) => {
            let arrayOriginal = questions2;
            response.Body?.forEach((itemQuestion) => {
              let listOptions = itemQuestion?.Values.map((item) => {
                return {
                  Option: item,
                  Value: item,
                };
              });
              const updatedOSArray = arrayOriginal.map((itemGroup) => {
                return itemGroup.groupTitle.toUpperCase() ===
                  itemQuestion.QuestionGroup.toUpperCase()
                  ? {
                      ...itemGroup,
                      questions: itemGroup.questions.map((itemQ) => {
                        if (
                          itemQ.Name.toUpperCase() ===
                          itemQuestion.Question.toUpperCase()
                        ) {
                          if (listOptions.length === 1) {
                            setValue(`${itemQ.Name}`, listOptions[0].Value, {
                              shouldValidate: true,
                            });
                          }
                          return { ...itemQ, Options: listOptions };
                        } else {
                          return itemQ;
                        }
                      }),
                    }
                  : itemGroup;
              });
              arrayOriginal = updatedOSArray;
            });
            setQuestions(arrayOriginal);
            setLoadView(false);
            questions2 = arrayOriginal;
          })
          .catch((error) => {
            setLoadView(false);
            /* Alert(
              {
                icon: "error",
                title: "Error de autentificación, inicia sesión nuevamente",
                allowOutsideClick: false,
                allowEscapeKey: false,
              },
              () => {
                navigate("/auth/login");
                Logout();
              }
            ); */
          });
      } else if (
        itemCurrent.ExecuteSearchFunction == "GetPreguntasRequeridas"
      ) {
        let value =
          valueParam === undefined || valueParam === null || valueParam == ""
            ? watch(`${itemCurrent.Id}`)
            : valueParam;
        let arrayOriginal = questions2;
        itemCurrent.ExecuteSearchDefinition?.filter(
          (itemOption) => itemOption.Option.toUpperCase() === value
        )[0]?.Requiered?.forEach((itemQuestion) => {
          const updatedOSArray = arrayOriginal.map((itemGroup) => {
            if (
              itemGroup.groupTitle.toUpperCase() ===
                itemQuestion.QuestionGroup.toUpperCase() ||
              path === `${constPathRoot.pathRoot}/inbox/new-file`
            ) {
              const obj2 = {
                ...itemGroup,
                questions: itemGroup.questions.map((itemQ) => {
                  if (
                    itemQ.Name.toUpperCase() ===
                    itemQuestion.Question.toUpperCase()
                  ) {
                    if (!itemQuestion?.Requiered) {
                      setValue(`${itemQ.Id}`, undefined);
                    }
                    const obj = {
                      ...itemQ,
                      Visible: itemQuestion?.Requiered,
                      Required:
                        file?.items?.Stage?.Name ==
                        "Cuestionario dinamico Documento"
                          ? false
                          : itemQuestion?.Requiered ?? itemQ?.Required ?? false,
                    };
                    return obj;
                  } else {
                    return itemQ;
                  }
                }),
              };
              return obj2;
            } else {
              return itemGroup;
            }
          });
          arrayOriginal = updatedOSArray;
        });

        let newUpdatearray = arrayOriginal.map((itemGroup) =>
          itemGroup.groupTitle.toUpperCase() ===
            itemCurrent?.GroupName?.toUpperCase() ||
          path === `${constPathRoot.pathRoot}/inbox/new-file`
            ? {
                ...itemGroup,
                questions: itemGroup.questions.map((itemQ) => {
                  !itemQ.Visible && clearErrors(`${itemQ.Id}`);
                  if (
                    itemQ.Name.toUpperCase() === itemCurrent.Name.toUpperCase()
                  ) {
                    return { ...itemQ, Value: value };
                  } else {
                    return itemQ;
                  }
                }),
              }
            : itemGroup
        );
        setQuestions(newUpdatearray);
        setLoadView(false);
        questions2 = newUpdatearray;
      }
    } catch (e) {
      console.log(".:UpdateCtrlQuestionsAsync:. Error: ", e);
    }
  };

  useEffect(() => {
    if (dataControls?.length > 0) {
      dataControls.forEach((itemGroup) => {
        itemGroup.questions?.forEach((itemQuestion) => {
          if (itemQuestion.QuestionType == "Fecha") {
            if (
              itemQuestion.Metadata != null &&
              itemQuestion.Metadata?.Value != null
            ) {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion.Metadata?.Value != null
                  ? new Date(itemQuestion.Metadata?.Value)
                  : null
              );
            }
          } else if (itemQuestion.QuestionType === "FechaPrevia") {
            if (
              itemQuestion.Metadata != null &&
              itemQuestion.Metadata?.Value != null
            ) {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion.Metadata?.Value != null
                  ? new Date(itemQuestion.Metadata?.Value)
                  : null
              );
            }
          } else if (itemQuestion.QuestionType === "Radio") {
            if (
              itemQuestion.Metadata != null &&
              itemQuestion.Metadata?.Value != null
            ) {
              setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value);
            }
          } else if (itemQuestion.QuestionType === "Hour") {
            if (
              itemQuestion.Metadata != null &&
              itemQuestion.Metadata?.Value != null
            ) {
              const specificTime = new Date();
              const splitHour = itemQuestion.Metadata?.Value?.split(":");
              specificTime.setHours(parseInt(splitHour[0]));
              specificTime.setMinutes(parseInt(splitHour[1]));
              console.log("specificTime 2", specificTime);
              setValue(`${itemQuestion.Id}`, specificTime);
            }
          } else {
            setValue(
              `${itemQuestion.Id}`,
              itemQuestion?.Metadata !== null &&
                itemQuestion?.Metadata !== undefined
                ? itemQuestion?.Metadata?.Value
                : itemQuestion?.Value
            );
          }
          if (itemQuestion?.ExecuteSearchDefinition) {
            UpdateCtrlQuestionsAsync(itemQuestion, "", questions);
          }
        });
      });
      const postalCodeField = dataControls[0]?.questions?.find(
        (field) => field.Name === "Código Postal"
      );
      if (!!postalCodeField?.Metadata?.Value) {
        GetFindPostalCode(
          postalCodeField?.Metadata?.Value,
          "",
          postalCodeField?.QuestionGroup
        );
      }
      clearErrors();
    } else if (
      props.StageCurrent !== null ||
      props.StageCurrent !== undefined
    ) {
      if (
        props.StageCurrent?.Stage !== undefined ||
        props.StageCurrent?.Stage !== null
      ) {
        const questionsToMap =
          props.StageCurrent?.Stage?.Name == "Cuestionario dinamico Documento"
            ? props.StageCurrent?.Stage?.DocumentalCategory?.DocumentalGroups[0]
                ?.DocumentTypes[0]?.QuestionGroups
            : props.StageCurrent?.Stage?.QuestionGroups;

        questionsToMap?.forEach((itemGroup) => {
          itemGroup.Questions?.forEach((itemQuestion) => {
            if (itemQuestion.QuestionType == "Fecha") {
              if (
                itemQuestion.Metadata != null &&
                itemQuestion.Metadata?.Value != null
              ) {
                setValue(
                  `${itemQuestion.Id}`,
                  itemQuestion.Metadata?.Value != null
                    ? new Date(itemQuestion.Metadata?.Value)
                    : null
                );
              }
            } else if (itemQuestion.QuestionType === "FechaPrevia") {
              if (
                itemQuestion.Metadata != null &&
                itemQuestion.Metadata?.Value != null
              ) {
                setValue(
                  `${itemQuestion.Id}`,
                  itemQuestion.Metadata?.Value != null
                    ? new Date(itemQuestion.Metadata?.Value)
                    : null
                );
              }
            } else if (itemQuestion.QuestionType === "Radio") {
              if (
                itemQuestion.Metadata != null &&
                itemQuestion.Metadata?.Value != null
              ) {
                setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value);
              }
            } else if (itemQuestion.QuestionType === "Hour") {
              if (
                itemQuestion.Metadata != null &&
                itemQuestion.Metadata?.Value != null
              ) {
                console.log(
                  "itemQuestion.Metadata?.Value:::",
                  itemQuestion.Metadata?.Value
                );
                const specificTime = new Date();
                const splitHour = itemQuestion.Metadata?.Value?.split(":");
                specificTime.setHours(parseInt(splitHour[0]));
                specificTime.setMinutes(parseInt(splitHour[1]));
                console.log("specificTime 3", specificTime);
                setValue(`${itemQuestion.Id}`, specificTime);
              }
            } else {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion?.Metadata !== null &&
                  itemQuestion?.Metadata !== undefined
                  ? itemQuestion?.Metadata?.Value
                  : itemQuestion?.Value
              );
            }
          });
        });
        clearErrors();
      }
    }
  }, [dataControls, props.StageCurrent]);

  useEffect(() => {
    if (dataSearchCP.items != undefined && questions.length > 0) {
      setLoadPostalCode(false);
      const idColonia = questions[0].questions.find(
        (itemQ) => itemQ.Name === "Colonia"
      )?.Id;
      const idCiudad = questions[0].questions.find(
        (itemQ) => itemQ.Name === "Ciudad"
      )?.Id;
      const idMunicipio = questions[0].questions.find(
        (itemQ) =>
          itemQ.Name === "Delegación o municipio" ||
          itemQ.Name === "Delegación y Municipio"
      )?.Id;
      const idEntidad = questions[0].questions.find(
        (itemQ) => itemQ.Name === "Entidad federativa"
      )?.Id;

      let listColonia = dataSearchCP.items?.Body?.find(
        (item) => item.Question == "Colonia"
      );
      let listCiudad = dataSearchCP.items?.Body?.find(
        (item) => item.Question == "Ciudad"
      )?.Values[0];
      let listMunicipio = dataSearchCP.items?.Body?.find(
        (item) =>
          item.Question === "Delegación o municipio" ||
          item.Question == "Delegación y Municipio"
      )?.Values[0];
      let listEstado = dataSearchCP.items?.Body?.find(
        (item) => item.Question == "Entidad federativa"
      )?.Values[0];

      var newArrayColonias = [];
      for (var i = 0; i < listColonia?.Values.length; i++) {
        var newObject = {};
        newObject.Option = listColonia?.Values[i];
        newObject.Value = listColonia?.Values[i];
        newArrayColonias.push(newObject);
      }

      if (newArrayColonias.length > 0) {
        let arrayOriginal = questions;
        let newUpdatearray = arrayOriginal?.map((itemGroup) =>
          itemGroup.groupTitle.toUpperCase() ===
          questions[0].groupTitle.toUpperCase()
            ? {
                ...itemGroup,
                questions: itemGroup.questions.map((itemQ) => {
                  if (itemQ.Name === "Colonia") {
                    unregister(`${idColonia}`);
                    return {
                      ...itemQ,
                      Options: newArrayColonias,
                      QuestionType: "Combo",
                    };
                  } else {
                    return itemQ;
                  }
                }),
              }
            : itemGroup
        );
        setQuestions(null);
        setQuestions(newUpdatearray);

        setValue(`${idCiudad}`, listCiudad, { shouldValidate: true });
        setValue(`${idMunicipio}`, listMunicipio, { shouldValidate: true });
        setValue(`${idEntidad}`, listEstado, { shouldValidate: true });
        setValue(`${idColonia}`, listColonia?.Values[0]?.toUpperCase(), {
          shouldValidate: true,
        });
      } else {
        let arrayOriginal = questions;
        let newUpdatearray = arrayOriginal?.map((itemGroup) =>
          itemGroup.groupTitle.toUpperCase() ===
          questions[0].groupTitle.toUpperCase()
            ? {
                ...itemGroup,
                questions: itemGroup.questions.map((itemQ) => {
                  if (itemQ.Name === "Colonia") {
                    unregister(`${idColonia}`);
                    return { ...itemQ, QuestionType: "Texto" };
                  } else {
                    return itemQ;
                  }
                }),
              }
            : itemGroup
        );
        setQuestions(null);
        setQuestions(newUpdatearray);

        setValue(`${idCiudad}`, "", { shouldValidate: true });
        setValue(`${idMunicipio}`, "", { shouldValidate: true });
        setValue(`${idEntidad}`, "", { shouldValidate: true });
        setValue(`${idColonia}`, "", { shouldValidate: true });
      }
    } else if (
      dataSearchCP != undefined &&
      dataSearchCP.error != undefined &&
      loadPostalCode
    ) {
      setLoadPostalCode(false);
      if (tryCP >= 0) {
        setTimeout(() => {
          retryCP();
        }, 500);
      } else {
        alert("Hubo un error al obtener la lista de colonias");
      }
    }
  }, [dataSearchCP]);

  const retryCP = () => {
    const cp = postalCodeTemp.code;
    const group = postalCodeTemp.group;
    let data = {
      File_Id: file.items.Id,
      Document_Id: null,
      DocumentType_Id: props.docId,
      QuestionGroup: group,
      Question: "Código Postal",
      Value: `${cp}`,
    };
    setTryCP(tryCP - 1);
    dispatch(executeSearchActions.GetByCodigoPostal(data));
  };

  let GetFindPostalCode = (code, name, group) => {
    if (code != null && code != "" && code?.length > 4) {
      setPostalCodeTemp({ code, group });
      setLoadPostalCode(true);
      let data = {
        File_Id: file.items.Id,
        Document_Id: null,
        DocumentType_Id: props.docId,
        QuestionGroup: group,
        Question: "Código Postal",
        Value: `${code}`,
      };
      dispatch(executeSearchActions.GetByCodigoPostal(data));
    }
  };

  const proccessData = (data) => {
    const uppercaseData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        return [
          key,
          typeof value === "string" &&
          !(
            key.toUpperCase().includes("CONTRASEÑA") ||
            key.toUpperCase().includes("CORREO")
          )
            ? value?.toUpperCase()
            : value,
        ];
      })
    );
    setDocToShow(null);
    setFormularioTerminado(true);
    props.handleSave(uppercaseData);
  };

  const onError = (errors, e) => {
    const fields = Object.keys(errors);
    if (fields.length > 0) {
      const field = fields[0];
      const element = document.querySelector(`input[name="${field}"]`);
      if (element) {
        element.focus();
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex !important",
        flexDirection: "column !important",
        alignItems: "center !important",
        position: "relative",
        width: "100% !important",
        padding: 3,
        overflowY: "auto",
        ["@media only screen and (max-width: 812px)"]: {
          height: "60vh",
        },
      }}
    >
      <FormProvider {...formMethods}>
        {loadView ? (
          <CustomLoadingOverlay
            active={loadView}
            text="Espera un momento por favor..."
          />
        ) : null}
        {questions.length > 0 &&
          questions.map((qn) => {
            return qn?.questions?.every(
              (questionInput) => !questionInput.Visible
            ) && path !== `${constPathRoot.pathRoot}/inbox/new-file` ? null : (
              <Accordion style={{ width: "75%" }} defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={questions.length > 1 ? <ExpandMore /> : null}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography key={qn.llave} style={{ fontSize: 18 }}>
                    {path === `${constPathRoot.pathRoot}/inbox/new-file`
                      ? "Completa el siguiente formulario con la información solicitada para registrar el alta del candidato."
                      : " Completa el siguiente formulario con la información solicitada."}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    wordSpacing: "2px",
                  }}
                >
                  <Stack sx={{ display: "flex", flexDirection: "row" }}>
                    {!!docToShow && (
                      <Box sx={{ width: "40%", pr: 2 }}>
                        <VisorPDF
                          pdfUrl={docToShow}
                          pdfName={"Document"}
                          height={"50vh"}
                          base64={base64SizePDF}
                        />
                      </Box>
                    )}
                    <Box sx={{ width: !!docToShow ? "60%" : "100%" }}>
                      {qn.questions?.map((itemInput) => {
                        if (itemInput.Visible === false) return null;
                        switch (itemInput.QuestionType.toUpperCase()) {
                          case "TEXTO":
                            return (
                              <Controller
                                control={control}
                                name={`${itemInput.Id}`}
                                render={({ field: { ref, ...restFields } }) => {
                                  return (
                                    <InputField
                                      inputType="text"
                                      control={control}
                                      register={register}
                                      name={`${itemInput.Id}`}
                                      label={itemInput.Name}
                                      validations={{
                                        required: itemInput.Required,
                                      }}
                                      defaultValue={
                                        itemInput?.Metadata?.Value?.toUpperCase() ??
                                        itemInput?.Value?.toUpperCase()
                                      }
                                      error={
                                        !!formState.errors[`${itemInput.Id}`]
                                      }
                                      helperText={
                                        formState.errors[`${itemInput.Id}`]
                                          ?.message
                                      }
                                      required={itemInput.Required}
                                      placeholder={itemInput.Description}
                                      disabled={itemInput.Disabled}
                                    />
                                  );
                                }}
                              />
                            );
                          case "CÓDIGO POSTAL":
                            return (
                              <Controller
                                control={control}
                                name={`${itemInput.Id}`}
                                render={({ field: { ref, ...restFields } }) => (
                                  <Box sx={{ mt: "20px" }}>
                                    <Typography
                                      sx={{
                                        "& .MuiFormControlLabel-label": {
                                          fontSize: 14,
                                        },
                                        display: "inline-flex",
                                        alignItems: "center",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: "16px !important",
                                        lineHeight: "140%",
                                        fontFamily:
                                          '"UniversNextforHSBC-Regular",sans-serif',
                                        color: "#000 !important",
                                        mb: 1,
                                        [theme.breakpoints.down("sm")]: {
                                          fontWeight: 400,
                                          fontSize: "9px !important",
                                          lineHeight: "144%",
                                        },
                                      }}
                                    >
                                      Código Postal
                                      <span style={{ fontWeight: 400 }}>*</span>
                                    </Typography>

                                    <TextField
                                      {...restFields}
                                      {...register(`${itemInput.Id}`, {
                                        required: "Requerido",
                                        minLength: {
                                          value: 5,
                                          message: "Código Postal. ej. 54960",
                                        },
                                      })}
                                      sx={{
                                        "&.Mui-focused fieldset": {
                                          borderColor: "black !important", // Color del borde cuando está enfocado
                                        },
                                        "& fieldset": {
                                          marginTop: "5px",
                                        },
                                      }}
                                      required={true}
                                      error={
                                        !!formState.errors[`${itemInput.Id}`]
                                      }
                                      helperText={
                                        formState.errors[`${itemInput.Id}`]
                                          ?.message
                                      }
                                      inputRef={ref}
                                      fullWidth
                                      defaultValue={
                                        itemInput?.Metadata?.Value?.toUpperCase() ??
                                        itemInput?.Value?.toUpperCase()
                                      }
                                      autoComplete="new-password"
                                      variant="outlined"
                                      disabled={itemInput.Disabled}
                                      placeholder={itemInput.Description}
                                      label={itemInput?.Label}
                                      onKeyUp={(e) => {
                                        GetFindPostalCode(
                                          e.target.value,
                                          `${itemInput.Id}`,
                                          itemInput.GroupName
                                        );
                                      }}
                                      InputProps={{
                                        sx: { borderRadius: "0px", p: 0 },
                                        inputProps: {
                                          maxLength: 5,
                                          style: { fontSize: "14px" },
                                          outline: "none",
                                          autoComplete: "new-password",
                                          form: {
                                            autoComplete: "off",
                                          },
                                        },
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Box> 
                                )}
                              />
                            );
                          case "PHONE":
                            return (
                              <InputField
                                inputType="phone"
                                control={control}
                                register={register}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                validations={{ required: itemInput.Required }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                                disabled={itemInput.Disabled}
                              />
                            );
                          case "RADIO":
                            return (
                              <InputField
                                disabled={itemInput.Disabled}
                                inputType="radio"
                                control={control}
                                register={register}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                radioValues={itemInput.Options}
                                required={itemInput.Required}
                                validations={{ required: true }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                onSelect={(e, value) => {
                                  setValue(`${itemInput.Id}`, e.target.value, {
                                    shouldValidate: true,
                                  });
                                  UpdateCtrlQuestionsAsync(
                                    itemInput,
                                    value,
                                    questions
                                  );
                                }}
                              />
                            );
                          case "COMBO":
                            return (
                              <InputField
                                disabled={itemInput.Disabled}
                                inputType="select"
                                control={control}
                                register={register}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                optionsList={itemInput.Options}
                                validations={{ required: itemInput.Required }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                                defaultValue={
                                  itemInput?.Metadata?.Value?.toUpperCase() ??
                                  itemInput?.Value?.toUpperCase()
                                }
                                onSelect={(e) => {
                                  setValue(`${itemInput.Id}`, e.target.value, {
                                    shouldValidate: true,
                                  });
                                  clearErrors(`${itemInput.Id}`);
                                  UpdateCtrlQuestionsAsync(
                                    itemInput,
                                    "",
                                    questions
                                  );
                                }}
                              />
                            );
                          case "FECHA":
                            return (
                              <InputField
                                disabled={itemInput.Disabled}
                                inputType="date"
                                control={control}
                                register={register}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                validations={{ required: itemInput.Required }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                                onSelect={(e) => {
                                  const d = new Date(e);
                                  if (isNaN(d.getTime())) {
                                    setError(
                                      `${itemInput.Id}`,
                                      {
                                        type: "validate",
                                        message:
                                          "La fecha ingresada no es válida",
                                      },
                                      { shouldFocus: true }
                                    );
                                  } else {
                                    setValue(`${itemInput.Id}`, e, {
                                      shouldValidate: true,
                                    });
                                    clearErrors(`${itemInput.Id}`);
                                  }
                                }}
                              />
                            );
                          case "FECHAPREVIA":
                            return (
                              <InputField
                                inputType="date"
                                register={register}
                                control={control}
                                minDate={itemInput?.minDate}
                                maxDate={
                                  !!itemInput?.maxDate
                                    ? itemInput.maxDate
                                    : new Date()
                                }
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                validations={{ required: itemInput.Required }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                                onSelect={(e) => {
                                  const d = new Date(e);
                                  const actDate = new Date().setHours(
                                    0,
                                    0,
                                    0,
                                    0
                                  );

                                  if (isNaN(d.getTime())) {
                                    // La fecha ingresada no es válida
                                    console.log(
                                      "Es valida NO ",
                                      itemInput.Id,
                                      e
                                    );
                                    setError(
                                      `${itemInput.Id}`,
                                      {
                                        type: "validate",
                                        message:
                                          "La fecha ingresada no es válida",
                                      },
                                      { shouldFocus: true }
                                    );
                                  } else if (
                                    d.getFullYear() <
                                    new Date().getFullYear() - 110
                                  ) {
                                    // La fecha es menor a 1914 (no debe ser la diferencia mas de 110 años)
                                    console.log(
                                      "Es valida menor ",
                                      itemInput.Id,
                                      e
                                    );
                                    setError(
                                      `${itemInput.Id}`,
                                      {
                                        type: "validate",
                                        message:
                                          "La fecha seleccionada no debe ser menor a 1914",
                                      },
                                      { shouldFocus: true }
                                    );
                                  } else if (
                                    !!itemInput?.maxDate
                                      ? d > new Date(itemInput?.maxDate)
                                      : d > actDate
                                  ) {
                                    // La fecha es mayor que itemInput.maxDate
                                    console.log(
                                      "Es valida mayor ",
                                      itemInput.Id,
                                      e
                                    );
                                    setError(
                                      `${itemInput.Id}`,
                                      {
                                        type: "validate",
                                        message:
                                          "La fecha seleccionada no debe ser mayor",
                                      },
                                      { shouldFocus: true }
                                    );
                                  } else {
                                    // La fecha es válida
                                    console.log(
                                      "Es valida else ",
                                      itemInput.Id,
                                      e
                                    );
                                    clearErrors(`${itemInput.Id}`);
                                    setValue(`${itemInput.Id}`, e, {
                                      shouldValidate: true,
                                    });
                                  }
                                }}
                                disabled={itemInput.Disabled}
                              />
                            );
                          case "CHECKBOX":
                            console.log("Check input: ", itemInput);
                            return (
                              <InputField
                                disabled={itemInput.Disabled}
                                inputType="check"
                                control={control}
                                register={register}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                radioValues={itemInput.Options}
                                checkTitle={itemInput.CheckTitle}
                                defaultValue={
                                  itemInput?.Metadata?.Value
                                    ? itemInput?.Metadata?.Value
                                    : itemInput?.Value
                                }
                                validations={{ required: true }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                              />
                            );
                          case "CURRENCY":
                            return (
                              <InputField
                                disabled={itemInput.Disabled}
                                inputType="currency"
                                control={control}
                                register={register}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                validations={{ required: itemInput.Required }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                                onSelect={(e) => {
                                  setValue(`${itemInput.Id}`, e, {
                                    shouldValidate: true,
                                  });
                                  clearErrors(`${itemInput.Id}`);
                                }}
                              />
                            );
                          case "EMAIL":
                            return (
                              <InputField
                                disabled={itemInput.Disabled}
                                inputType="email"
                                control={control}
                                register={register}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                validations={{ required: itemInput.Required }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                              />
                            );
                          case "CLABE":
                            return (
                              <InputField
                                disabled={itemInput.Disabled}
                                inputType="clabe"
                                control={control}
                                register={register}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                validations={{ required: itemInput.Required }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                              />
                            );
                          case "NUMBER":
                            return (
                              <InputField
                                disabled={itemInput.Disabled}
                                inputType="number"
                                control={control}
                                register={register}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                validations={{ required: itemInput.Required }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                              />
                            );
                          case "HOUR":
                            return (
                              <InputField
                                inputType="hour"
                                register={register}
                                control={control}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                validations={{ required: itemInput.Required }}
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                                disabled={itemInput.Disabled}
                                onSelect={(e) => {
                                  setValue(`${itemInput.Id}`, e);
                                }}
                              />
                            );
                          default:
                            return (
                              <InputField
                                disabled={itemInput.Disabled}
                                inputType="text"
                                control={control}
                                register={register}
                                name={`${itemInput.Id}`}
                                label={itemInput.Name}
                                validations={{ required: itemInput.Required }}
                                defaultValue={
                                  itemInput?.Metadata?.Value?.toUpperCase() ??
                                  itemInput?.Value?.toUpperCase()
                                }
                                error={!!formState.errors[`${itemInput.Id}`]}
                                helperText={
                                  formState.errors[`${itemInput.Id}`]?.message
                                }
                                required={itemInput.Required}
                              />
                            );
                        }
                      })}
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}

        <Button
          disabled={
            Object.keys(formState.errors).length !== 0 || disabledContinueButton
          }
          fullWidth
          variant="contained"
          sx={{
            height: 45,
            px: 10,
            marginTop: "0.5em",
            minWidth: 220,
            maxWidth: 350,
          }}
          onClick={handleSubmit(proccessData, onError)}
        >
          {props.TextButton === undefined || props.TextButton === null
            ? "Guardar"
            : props.TextButton}
        </Button>
      </FormProvider>
    </Box>
  );
}
