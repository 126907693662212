import axios from "axios";
import { Alert } from "../components";
import { Logout } from "../utils";

export const setupAxiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let err = error;
      console.log("NetworkError: ", error);
      if(error?.response?.status === 500) return null //Doesnt show auth alert
      if (
        error?.response?.status === 401 ||
        error?.code === 401 ||
        error?.message === "Network Error"
      ) {
        err = error.message;
       /*  if (window.location.pathname.includes("auth")) {
          return null;
        } */
        Alert(
          {
            icon: "error",
            title: "Error de autentificación, inicia sesión nuevamente",
            allowOutsideClick: false,
            allowEscapeKey: false,
          },
          (res) => {
            if (res?.isConfirmed) {
              Logout();
              window.location.href = "/auth/login";
            }
          }
        );
      }
      return Promise.reject(err);
    }
  );
};
