import { Badge, Box, IconButton, useTheme } from "@mui/material";
import React, { useState } from "react";
import { UseImage } from "../UseImage";
import MaintenanceIcon from "../../assets/icons/maintenanceIcon.png";
import { Alert } from "../Alert";
// import { mainStyles, theme } from '../styles'

export default function MaintenanceView({MaintenanceText}) {
  const theme = useTheme();

const handleOpenMaintenanceAlert = ()=>{
  Alert({icon: "warning", title: "Aviso Importante: Mantenimiento programado", text: MaintenanceText ?? ""})
}

  return (
    <>
      <Box
        style={{
          marginLeft: ".8em",
          color: theme.palette.userStyle.icNotificaciones,
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          style={{ color: theme.palette.userStyle.icNotificaciones }}
          onClick={handleOpenMaintenanceAlert}
        >
          <Badge color="error" badgeContent={""}>
            <UseImage
              src={MaintenanceIcon}
              style={{ width: "30px" }}
              type="img"
            />
          </Badge>
        </IconButton>
      </Box>
    </>
  );
}
